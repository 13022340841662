import React from "react";
import PropTypes from "prop-types";
import NewLargeStyles from "./NewLargeStyles";
import LargeCont from "./LargeCont";

const NewLargeFeatures = (props) => {
  const { sectionData, bgDark } = props;
  return (
    <section
      className="large-features-new relative border-box w-full"
      style={{ background: `${bgDark ? "#090D12" : "#fff"}` }}
    >
      {sectionData.sectionBlocks.map((data, idx) => (
        <LargeCont sectionData={data} idx={idx} key={data.blockTitle} />
      ))}
      <NewLargeStyles />
    </section>
  );
};

NewLargeFeatures.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  bgDark: PropTypes.bool,
};
NewLargeFeatures.defaultProps = {
  sectionData: {},
  bgDark: true,
};

export default NewLargeFeatures;
