import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import { useInView } from "react-intersection-observer";
import Image from "../../ImageQuerys/LargeFeaturesImages";
import NewLargeStyles from "./NewLargeStyles";
import CtaPrimary from "../../Buttons/primary-cta";

const container = {
  show: {
    transition: {
      staggerChildren: 0.35,
      delay: 1,
    },
  },
};

const item = {
  hidden: {
    opacity: 0,
    y: 200,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1.6,
      delay: 1,
    },
    exit: {
      opacity: 0,
      y: -200,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  },
};

const LargeCont = (props) => {
  const { sectionData, idx, custom } = props;
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("show");
    }
    if (!inView) {
      controls.start("exit");
    }
  }, [controls, inView]);
  return (
    <>
      {idx % 2 === 0 ? (
        <motion.div
          ref={ref}
          variants={container}
          initial={isMobileOnly ? "show" : "hidden"}
          animate={controls}
        >
          <div className="grid grid-cols-1 xl:grid-cols-2 max-w-1440 px-4 lg:mx-auto lg:px-40 pb-[100px] ">
            <div className="lg:mr-8">
              <motion.div variants={item}>
                <h2 className="mt-0 mb-8 lg:my-10 lg:mb-10 text-h3 text-white font-bold ">
                  {sectionData.blockTitle}
                  <span className="text-primary">.</span>
                </h2>
                <p className="xl:max-w-[438px] text-p lg:text-p-large text-black-600 ">
                  {sectionData.blockSubTitle}
                </p>
                {sectionData.ctaPrime && (
                  <CtaPrimary
                    ctaTitle={sectionData.ctaPrime.ctaTitle}
                    target={sectionData.ctaPrime.ctaTarget}
                    className="px-[24px] py-[10px] rounded-full"
                  />
                )}
              </motion.div>
            </div>
            <div className="mb-[100px] lg:mb-0">
              <motion.div variants={item}>
                <Image
                  imageName={sectionData.blockImg}
                  className="w-full md:max-w-80-percent md:mx-auto lg:max-w-full lg:mx-0"
                />
              </motion.div>
            </div>
          </div>
        </motion.div>
      ) : (
        <motion.div
          ref={ref}
          variants={container}
          initial={isMobileOnly ? "show" : "hidden"}
          animate={controls}
        >
          <div className="grid grid-cols-1 xl:grid-cols-2 max-w-1440 px-4 lg:mx-auto lg:px-40 pb-[100px] ">
            <div className="mt-0 xl:mt-[200px] mb-[100px] lg:mb-0">
              <motion.div variants={item}>
                <Image
                  imageName={sectionData.blockImg}
                  className="w-full md:max-w-80-percent md:mx-auto lg:mx-0 lg:max-w-full xl:order-first order-last"
                />
              </motion.div>
            </div>
            <div className="lg:pl-8 xl:order-last order-first">
              <motion.div variants={item}>
                <h2 className="mt-0 mb-8 lg:my-10 text-h3 text-white font-bold">
                  {sectionData.blockTitle}
                  <span className="text-primary">.</span>
                </h2>
                <p className="xl:max-w-[438px] text-p lg:text-p-large text-black-600">
                  {sectionData.blockSubTitle}
                </p>
                {sectionData.ctaPrime && (
                  <CtaPrimary
                    ctaTitle={sectionData.ctaPrime.ctaTitle}
                    target={sectionData.ctaPrime.ctaTarget}
                    className="cta-primary"
                  />
                )}
              </motion.div>
            </div>
          </div>
        </motion.div>
      )}
      <NewLargeStyles />
    </>
  );
};

LargeCont.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  custom: PropTypes.objectOf(PropTypes.any),
  idx: PropTypes.number,
};
LargeCont.defaultProps = {
  sectionData: {},
  custom: {},
  idx: null,
};

export default LargeCont;
