import React from "react";
import { viewports, colors } from "../../../style-vars";

const { xsOnly, smOrSmaller, mdOrSmaller } = viewports;
const { white, black } = colors;

const NewLargeStyles = () => (
  <style jsx>{`
    .large-features-new {
      position: relative;
    }
  `}</style>
);

export default NewLargeStyles;
